import { create } from '@storybook/theming'

export default create({
  base: 'light',
  brandTitle: 'Wizzie IO',
  brandUrl: 'https://wizzie.io',
  // To control appearance:
  brandImage: 'https://wizzie.io/wp-content/uploads/2018/07/logo_animated.png',
  fontBase: `-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'SF Pro Display', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  fontCode: `'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono',
  'Ubuntu Mono', Menlo, Consolas, Courier, monospace`,
})
